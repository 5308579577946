$(function() {

	Vue.component('login-form', {
		data: function() {
			return {
				loginFormObj: {
					login: {
						error: false,
						errorMessage: '',
						username: null,
						password: null

					}
				}
			};
		},
		methods: {
			login: function(e) {
				var self = this;
				e.preventDefault();

				var sendData = {
					username: self.loginFormObj.login.username,
					password: self.loginFormObj.login.password
				};

				$.ajax({
					url: '/lib//provider/login.php',
					data: sendData,
					method: 'post',
					dataType: 'json',
					complete: function(response,status) {

						var resJson = false;
						if(response && response.responseJSON) {
							resJson = response.responseJSON;

							if(resJson.status === true) {
								self.loginFormObj.login.error = false;
								self.loginFormObj.login.errorMessage = '';

								window.location = '/';
							} else {
								self.showError();
							}
						} else {
							self.showError();
						}
					}
				});
			},
			showError: function() {
				this.loginFormObj.login.error = true;
				this.loginFormObj.login.errorMessage = 'Fel användarnamn eller lösenord';
			}
		}
	});
});